<template>
  <div class="RefundOrderOne">
    <van-nav-bar v-if="xcx != 1"
      title="选择售后类型"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-notice-bar
      left-icon="volume-o"
      wrapable
      v-if="order.status==1"
      :scrollable="false"
      text="订单已进入仓库发货，请您耐心等待，取消已发出订单将产生物流费用"
    />
    <div class="order">
      <van-cell :border="false" class="order_sn">
        <template #title>
          <div @click="copy(order.ordersn)">
            {{order.ordersn}}
            <van-button size="mini">复制</van-button>
          </div>
        </template>
        <template #right-icon>
          <div>
            <div v-if="order.status == 1 ? true : false">
              {{order.is_outorder==1 ? '已提单，' : '未提单，'}}{{order.status_str}}
            </div>
            <div v-if="order.status > 1 ? true : false">
              {{order.status_str}}
            </div>
          </div>
        </template>
      </van-cell>
      <div class="goods_list">
        <van-card
          v-for="(item, index) in order.goods"
          :key="index"
          :desc="item.optionname"
          :title="item.goodstitle"
          :thumb="item.goodsthumb"
        />
      </div>
    </div>
    <div class="salse_list">
      <van-cell-group title="请选择对应的售后类型 我们将第一时间为您处理">
        <van-cell center title="申请退货" value="需退还收到的货物" is-link :replace="true" :to="{ name: 'serviceDetail', query: { id: id, rtype: 1, _t: 3}}" />
        <van-cell center title="申请换货" value="需退还收到的货物" is-link :replace="true" :to="{ name: 'serviceDetail', query: { id: id, rtype: 2, _t: 3}}" />
        <van-cell center title="申请维修" value="需寄回收到的货物" is-link :replace="true" :to="{ name: 'serviceDetail', query: { id: id, rtype: 3, _t: 3}}" />
        <van-cell center title="物流问题" value="未收到货品" is-link :replace="true" :to="{ name: 'serviceDetail', query: { id: id, rtype: 4, _t: 3}}" />
        <van-cell center title="其他" value="" is-link :replace="true" :to="{ name: 'serviceDetail', query: { id: id, rtype: 5, _t: 3}}" />
      </van-cell-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "RefundOrderOne",
  data(){
    return {
      xcx: 0,
      id: 0,
      order: {}, // 订单信息
      list: [], // 售后信息
      refund: {}, // 退款售后信息
      showAddinfo: false, // 显示输入单号信息弹框
      rexpresssn: '', // 退回单号
      addressTong: 1,
      addressInfo: {}, // 新增地址信息
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.xcx = this.$route.query.xcx ? parseInt(this.$route.query.xcx) : 0;
    this.init();
  },
  methods: {
    init() {
      this.getorder(); // 获取订单信息
    },

    onClickLeft() {
      this.$router.back();
    },

    getorder() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID")
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "shopsmobile/order/detail",
          _that.$qs.stringify({
            id: _that.id,
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.order = res.data.data;
          }
        })
        .catch(err => {
          console.log(err, '获取订单信息失败');
        })
    },
    
    /**
     * 复制订单号
     */
    copy(ordersn) {
      if (ordersn) {
        let _that = null;
        _that = this;
        _that.$copyText(ordersn).then(
          function(e) {
            _that.$toast("复制成功");
            console.log(e);
          },
          function(e) {
            console.log(e);
          }
        );
      }
    },
    
    // 子商户绑定会员
    setmerchMber() {
      let _that = null;
      _that = this;
      if (localStorage.getItem("DK_ISLOGIN") && this.merchid > 0) {
        _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID")
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/merch_adduser",
            _that.$qs.stringify({
              merchid: _that.merchid
            })
          )
          .then(res => {
            console.log(res);
          })
          .catch(error => {
            console.log(error);
          })
      }
    },
  }
};

</script>

<style lang="less">
.RefundOrderOne {
  text-align: left;
  min-height: 100%;
  background-color: #ededed;
  overflow: hidden;
  padding: 0;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .order {
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    .order_sn {
      .van-cell__title {
        flex: 1;
      }
    }
    .goods_list {
      .van-card {
        background-color: #fff;
        margin-top: 0;
      }
    }
  }
  .salse_list {
    margin: 10px;
    overflow: hidden;
    .van-cell-group__title {
      padding: 16px 0 8px;
      font-size: 12px;
      color: #333;
      font-weight: bold;
    }
    .van-cell-group {
      border-radius: 10px;
      overflow: hidden;
      .van-cell {
        line-height: 30px;
        .van-cell__title {
          flex: 1;
        }
      }
    }
  }
}
</style>
